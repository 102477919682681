<template>
  <div class="container-fluid">
    <Load :isLoading="isLoading" />
    <div class="row">
      <div class="col">
        <h1>
          Relatório de treinamentos em vencimento
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="nome">Períodos</label>
          <div class="row">
            <div class="col-12">
              <Checkbox @checkOption="checkItem" valueProp="15"></Checkbox>
              <span class="ml-5">15 dias</span>
            </div>
            <div class="col-12">
              <Checkbox @checkOption="checkItem" valueProp="30"></Checkbox>
              <span class="ml-5">30 dias</span>
            </div>
            <div class="col-12">
              <Checkbox @checkOption="checkItem" valueProp="45"></Checkbox>
              <span class="ml-5">45 dias</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label for="nome">Empresa</label>
          <select2 
            :ItemsProp="empresas"
            :FieldProp="'nome_fantasia'"
            :PlaceholderTextProp="'Pesquisar empresa'"
            :ClearAfterSelectedProp="false"
            :SelectedItemProp="empresaSelecionada"
            @Selected="EmpresaSelecionada($event)">  
          </select2>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label for="nome">Pessoa</label>
          <select2 
            :ItemsProp="filteredPessoas"
            :FieldProp="'nome'"
            :PlaceholderTextProp="'Pesquisar pessoa'"
            :ClearAfterSelectedProp="false"
            :SelectedItemProp="pessoaSelecionada"
            @searchText="filteredData"
            @Selected="PessoaSelecionada($event)">  
          </select2>
        </div>
      </div>
      <div class="mr-5 align-self-center">        
        <div class="form-group">
          <button type="button" class="btn btn-primary" @click="gerarRelatorio">GERAR</button>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col">
        <table class="table table-hover border table-striped table-bordered" v-if="dataItems.length > 0">
          <thead>
            <td>Nome Pessoa</td>
            <td>Empresa</td>
            <td>Treinamento</td>
            <td>Data realização</td>
            <td>Tempo de reciclagem</td>
            <td>Dias para reciclagem</td>
          </thead>
          <tbody>
            <tr v-for="(item, index) in dataItems" :key="index">
              <td>{{item.nome_funcionario}}</td>
              <td>{{item.nome_empresa}}</td>
              <td>{{item.nome_treinamento}}</td>
              <td>{{item.data_fim_treinamento | maskdateptbr}}</td>
              <td>{{item.dias_reciclagem}} dias</td>
              <td>{{periodoPositivo(item.periodo_reciclagem)}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
//import treinamentoRepository from './../../../services/api/treinamentoRepository'
import relatoriosRepository from './../../../services/api/relatoriosRepository'
import empresaRepository from './../../../services/api/empresaRepository'
import funcionariosRepository from './../../../services/api/funcionariosRepository'
import select2 from './../../../uicomponents/select2'
import Checkbox from './../../../uicomponents/Checkbox'
export default {
  components: {
    select2,
    Checkbox
  },
  data () {
    return {
      isLoading: false,
      empresaSelecionada: null,
      pessoaSelecionada: null,
      // treinamentoSelecionado: null,
      treinamentos: [],
      empresas: [],
      filteredPessoas: [],
      pessoas: [],
      dataItems: [],
      periodos: []
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    filteredData (searchText) {
      let filterKey = searchText && searchText.length > 0 && searchText.toLowerCase()
      //console.log(this.funcionarios)
      let data = [...this.pessoas]
      console.log(data)
      
      if (filterKey) {
        data = data.filter(function (row) {
          if(String(row.cpf).indexOf(filterKey) > -1 || row.nome.toLowerCase().indexOf(filterKey) > -1 || row.nome_cpf.toLowerCase().indexOf(filterKey) > -1) {
            return row
          }
        })
      }
      this.filteredPessoas = [...data]
      //console.log(this.filteredFuncionarios)
      if(this.filteredPessoas.length == 0) {
        this.listarFuncionarios(filterKey)
      }
      //this.buscaTreinamentoLength = searchText.length
    },
    periodoPositivo (periodo) {
      return parseInt(periodo) < 0 ? 'Treinamento vencido' : `${parseInt(periodo)} dias`
    },
    listarFuncionarios(search = null) {
      this.$store.commit("setSplashScreen", true)
      funcionariosRepository.listarFuncionarios(search).then(response => {
        this.$store.commit("setSplashScreen", false)
        if(response.data.success) {
          // this.pessoas = response.data.data
          this.filteredPessoas = this.pessoas = response.data['data'].map(obj => {
            let cpf = obj.cpf !== null ? obj.cpf : 'CPF não cadastrado'
            obj['nome_cpf'] = `${obj.nome} (${cpf})`
            return obj
          })
        }
      }).catch(error => {
        console.log(error)
        this.$store.commit("setSplashScreen", false)
      })
      //let responseF = await funcionariosRepository.listarFuncionariosFull()
      // if(responseF.data['success']) {
      //   this.pessoas = responseF.data['data']
      // }
    },
    async fetch() {
      try {
        this.isLoading = true
        // let responseT = await treinamentoRepository.listarTreinamentos()
        // if(responseT.data['success']) {
        //   this.treinamentos = responseT.data['data'].map(item => {
        //     item.nome_treinamento = `${item.codigo} - ${item.nome_atividade}`
        //     return item
        //   })
        // }
        let responseE = await empresaRepository.listarUnidades()
        if(responseE.data['success']) {
          this.empresas = responseE.data['data']
        }
        await this.listarFuncionarios()
      }catch (e) {
        console.log(e)
        this.isLoading = false
        this.$swal({
          icon: 'error',
          text: 'Não foi possível buscar os dados básicos de filtro!'
        })
      }finally {
        this.isLoading = false
        this.$swal({
          icon: 'info',
          text: 'Informe pelo menos um filtro!'
        })
      }
    },
    // TreinamentoSelecionado(treinamento) {
    //   this.treinamentoSelecionado = treinamento
    // },
    EmpresaSelecionada(empresa) {
      this.empresaSelecionada = empresa
    },
    PessoaSelecionada(pessoa) {
      this.pessoaSelecionada = pessoa
    },
    gerarRelatorio() {
      if (this.empresaSelecionada == null && this.pessoaSelecionada == null && this.periodos.length == 0) {
        this.$swal({
          icon: 'info',
          text: 'Informe pelo menos um filtro!'
        })
        return
      }
      let data = {
        empresa_id: this.empresaSelecionada != null ? this.empresaSelecionada.id : null,
        periodos: this.periodos,
        pessoa_id: this.pessoaSelecionada != null ? this.pessoaSelecionada.id : null
      }
      this.dataItems = []
      this.isLoading = true
      relatoriosRepository.treinamentoVencido(data).then(response => {
        this.isLoading = false
        if(response.data['success']) {
          this.dataItems = response.data['data']
        }
      }).catch(() => {
        this.isLoading = false
        this.$swal({
          icon: 'error',
          text: 'Ocorreu um erro ao gerar o relatório!'
        })
      })
    },
    checkItem(isChecked, item) {
      if(isChecked) {
        let index = this.periodos.findIndex(f => parseInt(f) == parseInt(item))
        // SE A PESSOA JA ESTIVER NA LISTA, ADICIONA SOMENTE A DATA
        if(index < 0) {
          // SE ELA NAO EXISTIR NA LISTA, CRIA O REGISTRO E ADICIONA A DATA
          this.periodos.push(item)
        }
      } else {
        // ACHA O INDICE DA PESSOA NA LISTA
        let index = this.periodos.findIndex(f => parseInt(f) == parseInt(item))
        // REMOVE A DATA DO ARRAY DE DATA DE FALTA
        this.periodos.splice(index, 1)
        
      }
      console.log(this.periodos)
    }
  }
}
</script>

<style>

</style>